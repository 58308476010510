import { PortableTextSpan, PortableTextTextBlock } from '@sanity/types'
export type HighlightedTextBlockInterface = Array<PortableTextTextBlock<PortableTextSpan>>
import { FunctionComponent } from 'react'
import * as stylex from '@stylexjs/stylex'
import { colorVariables } from '../../Styles/colors.stylex'

const styles = stylex.create({
    highlighted: {
        position: 'relative',
        color: colorVariables.FIERY_ORANGE,
    },
    marked: {
        position: 'relative',
        color: colorVariables.WHITE,
        '::after': {
            content: '',
            position: 'absolute',
            display: 'inline-block',
            zIndex: -1,
            left: -4,
            right: -4,
            bottom: -2,
            top: -2,
            backgroundColor: colorVariables.FIERY_ORANGE,
            borderRadius: 4    
        },
    }
})

export const HighlightedTextBlock: FunctionComponent<{ highlightableText: HighlightedTextBlockInterface, marked?: boolean }> = ({ highlightableText, marked }) => {
    if (!highlightableText) return null
    if (!highlightableText[0]) return null
    if (!highlightableText[0].children) return null
    if (!highlightableText[0].children[0]) return null

    return (
        <>
            {highlightableText[0].children.map((v) => {
                if (v.marks && v.marks[0] === 'strong') {
                    return (
                        <strong {...stylex.props(styles.highlighted, marked && styles.marked)} key={v._key}>
                            {v.text}
                        </strong>
                    )
                }
                return <span key={v._key}>{v.text}</span>
            })}
        </>
    )
}
