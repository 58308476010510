import { TypeAnimation } from 'react-type-animation'

type TextTypeAnimationType = {
  textArray: Array<string>
  interval?: number
}

export const TextTypeAnimation: React.FC<TextTypeAnimationType> = ({ textArray, interval = 3000}) => {
  const typeAnimationArray: Array<string|number> = [1000]
  textArray.forEach((textAnimationItem) => {
    typeAnimationArray.push(textAnimationItem)
    typeAnimationArray.push(interval)
  })
  return <TypeAnimation sequence={typeAnimationArray} repeat={Infinity} speed={1} deletionSpeed={20} />
}
