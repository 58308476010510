import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { TranslationInterface } from '../../../types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const LANGUAGE_ENGLISH = 'en'
export const LANGUAGE_DUTCH = 'nl'
export const DEFAULT_LANGUAGE = LANGUAGE_ENGLISH
const AVAILABLE_LANGUAGES = [LANGUAGE_ENGLISH, LANGUAGE_DUTCH]

type I18nContextInterface = { activeLanguage: string; availableLanguages: Array<string> }

const I18nContext = createContext<I18nContextInterface>({
    activeLanguage: '',
    availableLanguages: AVAILABLE_LANGUAGES,
})

export const I18n: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { pathname } = useLocation()
    const languageFromPath = pathname.split('/')[1]
    const availableLanguages = AVAILABLE_LANGUAGES

    const navigate = useNavigate()

    useEffect(() => {
        // Redirect to default language if no language is set
        if ('' === languageFromPath) {
            navigate(`/${DEFAULT_LANGUAGE}`)
        }
    }, [])

    return <I18nContext.Provider value={{ activeLanguage: languageFromPath, availableLanguages }}>{children}</I18nContext.Provider>
}

export const useLanguage = (): I18nContextInterface => {
    return useContext(I18nContext)
}

type Translatable = any
type K = string
type T = { [key: K]: TranslationInterface<Translatable> }
export const useTranslation = (variables: T): { [key: K]: Translatable } => {
    const { activeLanguage } = useLanguage()

    let translations: { [key: K]: T } = {}
    Object.entries(variables).forEach(([key, value]) => {
        if (!value) {
            return ''
        }
        if (value.hasOwnProperty(activeLanguage)) {
            translations[key] = getDefaultTranslation(value)
        }
        translations[key] = value[activeLanguage]
    })

    return translations || 'test'
}

export const getDefaultTranslation = (variable: TranslationInterface<T>): T => {
    return variable[DEFAULT_LANGUAGE]
}
