import * as stylex from '@stylexjs/stylex'
import { ValueAnimationTransition, motion, useAnimate } from 'framer-motion'
import { useControls } from 'leva'
import { FunctionComponent, MutableRefObject, useEffect, useRef } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { colorVariables } from '../../Styles/colors.stylex'
import { useDevMode } from '../../Utils/Hooks/DevMode'
import { Mouse, useMouse } from '../../Utils/Hooks/MousePosition'
import { IS_TOUCH_QUERY } from '../../Utils/MediaQueries'

const PRIMARY_MOUSE_SIZE = 20
const SECONDARY_MOUSE_SIZE = 50

const styles = stylex.create({
    primaryCursor: {
        position: 'fixed',
        backgroundColor: colorVariables.DEEP_BLUE,
        width: PRIMARY_MOUSE_SIZE,
        height: PRIMARY_MOUSE_SIZE,
        borderRadius: '100%',
        zIndex: 9999,
        pointerEvents: 'none',
        opacity: 0,
    },
    secondaryCursor: {
        position: 'fixed',
        border: `1px solid ${colorVariables.DEEP_BLUE}`,
        width: SECONDARY_MOUSE_SIZE,
        height: SECONDARY_MOUSE_SIZE,
        borderRadius: '100%',
        zIndex: 9998,
        pointerEvents: 'none',
        opacity: 0,
    },
    initiated: {
        opacity: 1,
    },
})

export let fancyPrimaryCursorRef: MutableRefObject<HTMLDivElement|null>|null = null
export let fancySecondaryCursorRef: MutableRefObject<HTMLDivElement|null>|null = null

export const FancyCursor: FunctionComponent = () => {
    const isDevmode = useDevMode()
    const isTouch = useMediaQuery(IS_TOUCH_QUERY)
    const mouse = useMouse()

    const { developCursorMode } = useControls(
        'Fancy cursor',
        {
            developCursorMode: { value: true, label: 'Develop cursor mode' },
        },
        { collapsed: false }
    )

    useEffect(() => {
        if ((isDevmode && !developCursorMode) || isTouch) {
            document.body.style.cursor = 'default'
            return
        }
        document.body.style.cursor = 'none'
    }, [developCursorMode])

    if (isTouch) {
        return null
    }

    return (
        <>
            <PrimaryCursor {...mouse} />
            <SecondaryCursor {...mouse} />
        </>
    )
}

const cursorMoveEaseOptions: ValueAnimationTransition = { ease: 'backOut', type: 'tween' }
const cursorClickEaseOptions: ValueAnimationTransition = { ease: 'backOut', type: 'tween' }

const PrimaryCursor: FunctionComponent<Mouse> = ({ position, isMoving, isInitiated, isClicked }) => {
    const [scope, animate] = useAnimate()
    const { FIERY_ORANGE, DEEP_BLUE } = colorVariables

    useEffect(() => {
        fancyPrimaryCursorRef = scope
    }, [])

    useEffect(() => {
        animate(scope.current, { scale: isMoving ? 0.25 : 1 }, { duration: 0.4, ...cursorMoveEaseOptions })
    }, [isMoving, isInitiated])

    useEffect(() => {
        animate(
            scope.current,
            { scale: isClicked ? 0.75 : 1, backgroundColor: isClicked ? FIERY_ORANGE : DEEP_BLUE },
            { duration: 0.4, ...cursorClickEaseOptions }
        )
    }, [isClicked])

    const variants = {
        default: {
            x: position.x - PRIMARY_MOUSE_SIZE / 2,
            y: position.y - PRIMARY_MOUSE_SIZE / 2,
        },
    }

    return (
        <motion.div
            {...stylex.props(styles.primaryCursor, isInitiated && styles.initiated)}
            variants={variants}
            animate="default"
            ref={scope}
            transition={{
                default: {
                    duration: 0.01,
                },
            }}
        />
    )
}

const SecondaryCursor: FunctionComponent<Mouse> = ({ position, isInitiated }) => {
    fancySecondaryCursorRef = useRef(null)
    const variants = {
        default: {
            x: position.x - SECONDARY_MOUSE_SIZE / 2,
            y: position.y - SECONDARY_MOUSE_SIZE / 2,
        },
    }

    return (
        <motion.div
            {...stylex.props(styles.secondaryCursor, isInitiated && styles.initiated)}
            variants={variants}
            animate="default"
            transition={{
                type: 'tween',
                ease: 'backOut',
            }}
            ref={fancySecondaryCursorRef}
        />
    )
}
