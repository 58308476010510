import { FunctionComponent, ReactElement, useReducer, useEffect, useRef } from 'react'

import sanityClient from './Client'

type T = unknown
type Data = T
type Cache<T> = { [url: string]: T }
type Action<T> = { type: 'loading' } | { type: 'success'; payload: T } | { type: 'success|cache'; payload: T } | { type: 'error'; payload: Error }

type FetcherData = {
    query: string
    children(data: Data): ReactElement
}

type useSanityFetcherType<T> = (query: string) => SanityResponseInterface<T> | null

export interface SanityResponseInterface<T> {
    data: T
    error?: Error
    action?: Action<T>
}

const initialState: SanityResponseInterface<T> = {
    error: undefined,
    data: undefined,
}

export const SanityFetcher: FunctionComponent<FetcherData> = ({ query, children }) => {
    const cache = useRef<Cache<T>>({})
    const cancelRequest = useRef<boolean>(false)

    if (null === query) {
        console.error('No query was given')
        return <p>No query was given</p>
    }
    const [state, dispatch] = useReducer(fetchReducer, initialState)

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: 'loading' })
            if (cache.current[query]) {
                dispatch({ type: 'success|cache', payload: cache.current[query] })
                return
            }

            try {
                const response = await sanityClient.fetch(query)

                if (response.error) {
                    throw new Error(response.statusText)
                }
                if (!response) {
                    dispatch({ type: 'loading' })
                    return
                }

                const data = response as T
                cache.current[query] = data
                dispatch({ type: 'success', payload: data })
            } catch (error) {
                if (cancelRequest.current) return

                dispatch({ type: 'error', payload: error as Error })
            }
        }

        void fetchData()

        return () => {
            cancelRequest.current = true
        }
    }, [query])

    return children(state)
}

const fetchReducer = (state: SanityResponseInterface<T>, action: Action<T>): SanityResponseInterface<T> => {
    switch (action.type) {
        case 'loading':
            return { ...initialState, action }
        case 'success':
            return { ...initialState, data: action.payload, action }
        case 'success|cache':
            return { ...initialState, data: action.payload, action }
        case 'error':
            return { ...initialState, error: action.payload, action }
        default:
            return state
    }
}



export const useSanityFetcher = <K,>(query: string): SanityResponseInterface<K> => {
    const cache = useRef<Cache<T>>({})
    const cancelRequest = useRef<boolean>(false)
    const [state, dispatch] = useReducer(fetchReducer, initialState)

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: 'loading' })
            
            if (cache.current[query]) {
                dispatch({ type: 'success|cache', payload: cache.current[query] })
                return
            }

            try {
                const response = await sanityClient.fetch(query)

                if (response.error) {
                    throw new Error(response.statusText)
                }
                if (!response) {
                    dispatch({ type: 'loading' })
                    return
                }

                const data = response as T
                cache.current[query] = data
                dispatch({ type: 'success', payload: data })
            } catch (error) {
                if (cancelRequest.current) return

                dispatch({ type: 'error', payload: error as Error })
            }
        }

        void fetchData()

        return () => {
            cancelRequest.current = true
        }
    }, [query])

    return state as SanityResponseInterface<K>
}
