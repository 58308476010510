import { defineVars } from "@stylexjs/stylex"

export const fontFamilyVariables = defineVars({
    lightFont: { default: 'Montserrat, sans-serif' },
    headingFont: { default: 'IBMPlexSans Bold, sans-serif' },
    serifFont: { default: 'PTSerif Regular, sans-serif' },
})

export const fontWeightVariables = defineVars({
    light: { default: '300' },
    regular: { default: '400' },
    bold: { default: '700' },
})