import * as stylex from '@stylexjs/stylex'
import { FunctionComponent, PropsWithChildren } from 'react'

type ContainerAlign = { align?: 'left' | 'center' | 'right' }
type ContainerProps = PropsWithChildren & ContainerAlign

const FROM_LARGE = '@media (min-width: 1200px)'

const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: '1440px',
    marginHorizontal: 'auto',
    paddingHorizontal: {
      default: 24,
      [FROM_LARGE]: 80
    }
  },
})

export const Container: FunctionComponent<ContainerProps> = ({ align, children, ...props }) => {
    
  return (
    <div {...stylex.props(styles.container)}>
      {children}
    </div>
  )
}
