import { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { useLanguage } from '../../Utils/Hooks/I18n'
import * as stylex from '@stylexjs/stylex'
import { colorVariables } from '../../Styles/colors.stylex'
import { fontWeightVariables } from '../../Styles/fonts.stylex'

const styles = stylex.create({
    mobileHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mobileHeaderLanguages: {
        marginRight: 8,
    },
    mobileHeaderLanguagesLanguage: {
        position: 'relative',
        color: colorVariables.DEEP_BLUE,
        fontSize: 16,
        fontWeight: fontWeightVariables.regular,
        marginVertical: 4,
        marginHorizontal: 8,
        transition: 'color 0.2s ease-in-out',
        '::after': {
            content: '',
            position: 'absolute',
            display: 'inline-block',
            backgroundColor: colorVariables.FIERY_ORANGE,
            bottom: 0,
            left: 0,
            right: 0,
            transform: 'scaleX(0)',
            height: 1,
            transition: 'transform 0.2s ease-in-out',
        },
        ':hover': {
            color: colorVariables.FIERY_ORANGE,
            '::after': {
                transform: 'scaleX(0.5)'
            }
        }
        
    },
    mobileHeaderLanguagesLanguageActive: {
        color: colorVariables.FIERY_ORANGE,
        '::after': {
            transform: 'scaleX(1)'
        }
    }
})

export const MobileHeader: FunctionComponent = () => {
    const { availableLanguages, activeLanguage } = useLanguage()

    const isActiveLanguage = (language: string) => (activeLanguage === language ? 'active' : '')

    return (
        <div {...stylex.props(styles.mobileHeader)}>
            <div {...stylex.props(styles.mobileHeaderLanguages)}>
                {availableLanguages.map((language) => {
                    return (
                        <NavLink key={language} {...stylex.props(styles.mobileHeaderLanguagesLanguage, isActiveLanguage(language) ? styles.mobileHeaderLanguagesLanguageActive : null)} to={`/${language}`}>
                            {language}
                        </NavLink>
                    )
                })}
            </div>
        </div>
    )
}
