import { useEffect, useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { IS_TOUCH_QUERY } from '../MediaQueries'

type MousePosition = { x: number; y: number }
type MouseIsMoving = boolean
type MouseIsClicked = boolean
type MouseIsInitiated = boolean
export type Mouse = { position: MousePosition; isMoving: MouseIsMoving; isClicked: MouseIsClicked; isInitiated: MouseIsInitiated }

export const useMouse = (): Mouse => {
    const isTouch = useMediaQuery(IS_TOUCH_QUERY)
    const [mousePosition, setMousePosition] = useState<MousePosition>({ x: window.screen.width / 2, y: window.screen.height / 2 })
    const [mouseIsMoving, setMouseIsMoving] = useState<MouseIsMoving>(false)
    const [mouseIsClicked, setMouseIsClicked] = useState<MouseIsClicked>(false)
    const [mouseIsInitiated, setMouseIsInitiated] = useState<MouseIsInitiated>(false)

    useEffect(() => {
        if (isTouch) {
            return
        }
        let isMovedTimeout: NodeJS.Timeout

        const mouseMove = ({ clientX = 0, clientY = 0 }: MouseEvent) => {
            setMousePosition({ x: clientX, y: clientY })
            setMouseIsMoving(true)

            clearTimeout(isMovedTimeout)
            isMovedTimeout = setTimeout(() => {
                setMouseIsMoving(false)
            }, 100)

            if (!mouseIsInitiated) {
                setMouseIsInitiated(true)
            }
        }

        if (!isTouch) {
            window.addEventListener('mousemove', mouseMove)
        }

        return () => {
            clearTimeout(isMovedTimeout)
            window.removeEventListener('mousemove', mouseMove)
        }
    }, [isTouch])

    useEffect(() => {
        const mouseDown = () => {
            setMouseIsClicked(true)
        }

        window.addEventListener('mousedown', mouseDown)        

        return () => {
            window.removeEventListener('mousedown', mouseDown)
        }
    }, [isTouch])

    useEffect(() => {
        const mouseRelease = () => {
            setMouseIsClicked(false)
        }

        window.addEventListener('mouseup', mouseRelease)        

        return () => {
            window.removeEventListener('mouseup', mouseRelease)
        }
    }, [isTouch])
    
    return { position: mousePosition, isMoving: mouseIsMoving, isClicked: mouseIsClicked, isInitiated: mouseIsInitiated }
}
