import { motion, useScroll, useTransform } from 'framer-motion'
import { FunctionComponent } from 'react'
import { Container } from '../Atom/Container'
import { HighlightedTextBlock, HighlightedTextBlockInterface } from '../Atom/HighlightableText'
import * as stylex from '@stylexjs/stylex'
import { typographyStyles } from '../../Styles/typogragraphy'

type IntroType = {
    title: HighlightedTextBlockInterface
    subtitle: string
}

const styles = stylex.create({
    intro: {
        paddingTop: {
            default: 20,
            '@media (min-width: 1200px)': 120,
        },
        paddingBottom: {
            default: 600,
            '@media (min-width: 1200px)': 120,
        },
        height: {
            default: null,
            '@media (min-width: 1200px)': '100vh',
        }
    },
    introTitle: {
        marginTop: 120,
    },
    introSubtitle: {
        marginTop: 20,  
    }
})

export const Intro: FunctionComponent<IntroType> = ({ title, subtitle, ...props }) => {
    return (
        <section {...stylex.props(styles.intro)} {...props}>
            <Container>
                <motion.h1 {...stylex.props(typographyStyles.h1, styles.introTitle)}>{title && <HighlightedTextBlock highlightableText={title} marked />}</motion.h1>
                <motion.h2 {...stylex.props(typographyStyles.h2, styles.introSubtitle)}>
                    {subtitle}
                </motion.h2>
            </Container>
        </section>
    )
}
