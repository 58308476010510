import { FunctionComponent } from 'react'
import { useDevMode } from '../../Utils/Hooks/DevMode'
import {Helmet} from "react-helmet";

export const GoogleTagManager: FunctionComponent = () => {
    if(useDevMode()) {
        return null
    }
    
    return (
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-49ZFTV57TS"></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
            function gtag(){
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', 'G-49ZFTV57TS');`}
            </script>
        </Helmet>
    )
}
