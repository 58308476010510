import * as stylex from '@stylexjs/stylex'
import { fontFamilyVariables, fontWeightVariables } from './fonts.stylex'
import { colorVariables } from './colors.stylex'

const FROM_LARGE = '@media (min-width: 1200px)'

const smallFonts = {
    display: 'inline-block',
    fontFamily: fontFamilyVariables.serifFont,
    fontWeight: fontWeightVariables.bold,
    color: colorVariables.DEEP_BLUE
}

export const typographyStyles = stylex.create({
    h1: {
        display: 'inline-block',
        fontFamily: fontFamilyVariables.headingFont,
        fontWeight: fontWeightVariables.bold,
        lineHeight: 60 / 44,
        letterSpacing: 4,
        color: colorVariables.DEEP_BLUE,
        textAlign: 'center',
        fontSize: {
            default: 44,
            [FROM_LARGE]: 72,
        },
    },
    h2: {
        display: 'inline-block',
        fontFamily: fontFamilyVariables.lightFont,
        lineHeight: 40 / 32,
        color: colorVariables.DEEP_BLUE,
        textAlign: 'center',
        fontSize: {
            default: 32,
            [FROM_LARGE]: 55,
        },
    },
    h3: {
        display: 'inline-block',
        fontFamily: fontFamilyVariables.headingFont,
        lineHeight: 40 / 28,
        color: colorVariables.DEEP_BLUE,
        textAlign: 'center',
        fontSize: {
            default: 28,
            [FROM_LARGE]: 48,
        },
    },
    p: smallFonts,
    li: {
        ...smallFonts,
        display: 'block',
        position: 'relative',
    },
    a: {
        ...smallFonts,
        cursor: 'none'
    },
    ul: {
        listStyle: 'trad-chinese-formal',
    },
})
