import { FunctionComponent, Suspense, lazy } from 'react'
import { TranslationInterface } from '../../types'
import { GoogleTagManager } from '../Components/Atom/GoogleTagManager'
import { HighlightedTextBlockInterface } from '../Components/Atom/HighlightableText'
import { MobileHeader } from '../Components/Molecule/MobileHeader'
import { AboutInfoType } from '../Components/Organism/About'
import { Intro } from '../Components/Organism/Intro'
import { LeftBar } from '../Components/Organism/LeftBar'
import { useTranslation } from '../Utils/Hooks/I18n'

export interface HomeDataInterface {
    name: TranslationInterface<string>
    nameChangers: Array<TranslationInterface<string>>
    title: TranslationInterface<HighlightedTextBlockInterface>
    subtitle: TranslationInterface<string>
    infoItems: AboutInfoType
    infoTitle: TranslationInterface<string>
}

const AwesomeGrid = lazy(() => import('../Components/Organism/AwesomeGrid'))

export const Home: FunctionComponent<HomeDataInterface> = ({ title, subtitle, name, nameChangers }) => {
    const translations = useTranslation({ title, name, subtitle })
    return (
        <Suspense fallback={'LOADING'}>
            {name && <LeftBar name={name} nameChangers={nameChangers ?? []} />}
            <MobileHeader />
            <div>
                <Intro title={translations.title} subtitle={translations.subtitle} />
                <Suspense>
                    <AwesomeGrid />
                </Suspense>
            </div>
            <GoogleTagManager />
        </Suspense>
    )
}
