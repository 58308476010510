import { FunctionComponent, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { FancyCursor } from './Components/Atom/FancyCursor'
import { Home, HomeDataInterface } from './Page/Home'
import { SanityFetcher, SanityResponseInterface } from './Utils/Fetcher'
import { I18n } from './Utils/Hooks/I18n'
import { LevaControls } from './Utils/LevaControls'
import './fonts.css'
import './reset.css'
import { Performance } from './Utils/Performance'

const BackgroundExperience = lazy(() => import('./Experience/BackgroundExperience'))

export const App: FunctionComponent = () => {
    return (
        <BrowserRouter>
            <I18n>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <FancyCursor />
                    <LevaControls />
                    <SanityFetcher
                        query={`*[ _type == "home"]{
                            title,
                            name,
                            nameChangers,
                            subtitle
                        }`}
                    >
                        {(response: SanityResponseInterface<Array<HomeDataInterface>>) => {
                            const responseData = response?.data?.[0]
                            return (
                                <Routes>
                                    <Route path="/:language" element={<Home {...responseData} />} />
                                </Routes>
                            )
                        }}
                    </SanityFetcher>
                    <Suspense>
                        <BackgroundExperience />
                    </Suspense>
                </QueryParamProvider>
            </I18n>
        </BrowserRouter>
    )
}
