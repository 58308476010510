import { defineVars } from '@stylexjs/stylex'

// Only for use in ThreeJS
export const MIDNIGHT_BLUE_HEX = '#151F30'
export const DEEP_BLUE_HEX = '#103778'
export const GREEN_BLUE_HEX = '#0593A2'
export const FIERY_ORANGE_HEX = '#FF7A48'
export const NOON_RED_HEX = 'E3371E'
export const LIGHT_GREY_HEX = '#EFEFEF'
export const WHITE_HEX = '#FFFFFF'

export const colorVariables = defineVars({
    MIDNIGHT_BLUE: { default: MIDNIGHT_BLUE_HEX },
    DEEP_BLUE: { default: DEEP_BLUE_HEX },
    GREEN_BLUE: { default: GREEN_BLUE_HEX },
    FIERY_ORANGE: { default: FIERY_ORANGE_HEX },
    NOON_RED: { default: NOON_RED_HEX },
    LIGHT_GREY: { default: LIGHT_GREY_HEX },
    WHITE: { default: WHITE_HEX },
})
