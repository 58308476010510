import * as stylex from '@stylexjs/stylex'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import { useInterval } from 'usehooks-ts'
import { TranslationInterface } from '../../../types'
import { LinkedInIcon } from '../../Assets/LinkedIn'
import { colorVariables } from '../../Styles/colors.stylex'
import { fontFamilyVariables } from '../../Styles/fonts.stylex'
import { TextTypeAnimation } from '../../Utils/Animations/TextTypeAnimation'
import { useLanguage, useTranslation } from '../../Utils/Hooks/I18n'

interface LeftBarInterface {
    name: TranslationInterface<string>
    nameChangers: Array<TranslationInterface<string>>
}

const TIME_BETWEEN_SWITCHING_NAMES = 5000

const FROM_LARGE = '@media (min-width: 1200px)'

const styles = stylex.create({
    leftBar: {
        display: { default: 'none', [FROM_LARGE]: 'flex' },
        position: 'fixed',
        zIndex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        left: 0,
        top: 0,
        bottom: 0,
        background: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
        boxShadow: '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff',
        width: 67,
        paddingVertical: 24,
    },
    leftBarTop: {
        paddingVertical: 24,
    },
    leftBarTopTitle: {
        fontFamily: fontFamilyVariables.lightFont,
        color: colorVariables.DEEP_BLUE,
        writingMode: 'vertical-lr',
        textOrientation: 'upright',
        textTransform: 'uppercase',
        letterSpacing: 4,
    },
})

export const LeftBar: React.FC<LeftBarInterface> = ({ name, nameChangers }) => {
    const { scrollYProgress } = useScroll()
    const scrollTranslateDistance = useTransform(scrollYProgress, [0, 0.5], ['0%', '-100%'])
    const scrollOpacityDistance = useTransform(scrollYProgress, [0, 0.1], [1, 0])

    const { activeLanguage } = useLanguage()
    const nameSwitchers = nameChangers.map((nameChanger) => nameChanger[activeLanguage])
    const [nameSwitch, setNameSwitch] = useState(0)
    const t = useTranslation({
        name,
    })
    useInterval(() => {
        if (nameSwitch === nameSwitchers.length - 1) {
            setNameSwitch(0)
            return
        }
        setNameSwitch(1)
    }, TIME_BETWEEN_SWITCHING_NAMES)

    const translatedName = t.name

    return (
        <motion.div {...stylex.props(styles.leftBar)} style={{ translateX: scrollTranslateDistance, opacity: scrollOpacityDistance }}>
            <div {...stylex.props(styles.leftBarTop)}>
                <span {...stylex.props(styles.leftBarTopTitle)}>
                    {translatedName && <TypeAnimation sequence={[translatedName, 1000]} speed={1} deletionSpeed={20} cursor={false} />}{' '}
                    <TextTypeAnimation textArray={nameSwitchers} />
                </span>
            </div>

            <div className="left-bar-bottom">
                <div className="left-bar-bottom-social">
                    <a
                        className="left-bar-bottom-social-link"
                        href="https://www.linkedin.com/in/jolan-de-nef-3a5519107/"
                        target="blank"
                        title="Go to my linkedin"
                    >
                        <LinkedInIcon />
                    </a>
                </div>
            </div>
        </motion.div>
    )
}
